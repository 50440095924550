@use '../../../../styles/size.scss' as v;
@import 'styles/layers.scss';

@layer component {
  .wrap {
    height: v.$height-table-filters;
    margin-bottom: 27px;
    width: 1083px;
  }
  
  .mr {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  .mr151 {
    margin-right: 151px;
  }

  .mr197 {
    margin-right: 197px;
  }
  
  .dropdown-toggle {
    background-color: transparent;
  }
  
  .dropdown-menu {
    padding: 0;
    background-color: var(--black14);
    &:hover {
      background: var(--black14);
    }
    right: 0;
  }

  .switcher {
    width: 34px;
    height: 34px;
    padding: 9px 16px;
  }
  
  @media screen and (max-width: 1120px) {
    .switcher {
      display: none;
    }
    .mr {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }  
}
